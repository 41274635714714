export enum Colors {
  white = '#FFFFFF',
  BleuHellio = '#006FBA',
  GrisHellio = '#3A3A3A',
  BeigeHellio = '#EDE9E6',
  GrandPublic = '#FBA03D',
  Collectivites = '#FF816D',
  Entreprises = '#0E399E',
  Agriculture = '#20A76A',
  Industriels = '#C6C2BE',
  Tertiaire = '#9E3C0C',
  OrangeHellio = '#FBA03D',
  GestionnaireHabitat = '#F13538',
}

export const ColorsVariations: { [key: string]: string } = {
  // bleu-hellio
  'bleu-hellio-100': '#C9F3FB',
  'bleu-hellio-200': '#94E1F8',
  'bleu-hellio-300': '#5DC2EA',
  'bleu-hellio-400': '#359FD6',
  'bleu-hellio-500': '#0070BB',
  'bleu-hellio-600': '#0056A0', // primary
  'bleu-hellio-700': '#004086',
  'bleu-hellio-800': '#002D6C',
  'bleu-hellio-900': '#002059',

  // agriculture
  'agriculture-100': '#D2FAD8',
  'agriculture-200': '#A6F6BA',
  'agriculture-300': '#75E49C',
  'agriculture-400': '#4FCA86',
  'agriculture-500': '#20A76A', // primary
  'agriculture-600': '#178F65',
  'agriculture-700': '#10785D',
  'agriculture-800': '#0A6053',
  'agriculture-900': '#06504C',

  // grand-public
  'grand-public-100': '#FEF3D8',
  'grand-public-200': '#FEE4B1',
  'grand-public-300': '#FDD18A',
  'grand-public-400': '#FCBE6D',
  'grand-public-500': '#FBA03D', // primary
  'grand-public-600': '#D77D2C',
  'grand-public-700': '#B45E1E',
  'grand-public-800': '#914213',
  'grand-public-900': '#782F0B',

  // entreprise
  'entreprise-100': '#CCDFFA',
  'entreprise-200': '#9BBEF5',
  'entreprise-300': '#6692E1',
  'entreprise-400': '#3E6AC4',
  'entreprise-500': '#0E399E', // primary
  'entreprise-600': '#0A2B87',
  'entreprise-700': '#072071',
  'entreprise-800': '#04165B',
  'entreprise-900': '#020F4B',

  // gestionnaire-habitat
  'gestionnaire-habitat-100': '#FEE3D6',
  'gestionnaire-habitat-200': '#FDC1AE',
  'gestionnaire-habitat-300': '#FA9885',
  'gestionnaire-habitat-400': '#F67066',
  'gestionnaire-habitat-500': '#F13538', // primary
  'gestionnaire-habitat-600': '#CF2638',
  'gestionnaire-habitat-700': '#AD1A36',
  'gestionnaire-habitat-800': '#8B1033',
  'gestionnaire-habitat-900': '#730A30',

  // pro-renov
  'pro-renov-100': '#FAE7CB',
  'pro-renov-200': '#F5CA9A',
  'pro-renov-300': '#E19E64',
  'pro-renov-400': '#C4723C',
  'pro-renov-500': '#9E3C0C', // primary
  'pro-renov-600': '#872A08',
  'pro-renov-700': '#711B06',
  'pro-renov-800': '#5B0F03',
  'pro-renov-900': '#4B0702',

  // industriels
  'industriels-100': '#FCFBFA',
  'industriels-200': '#F9F7F5',
  'industriels-300': '#EDEBE8',
  'industriels-400': '#DCD9D6',
  'industriels-500': '#C6C2BE', // primary
  'industriels-600': '#AA998A',
  'industriels-700': '#8E725F',
  'industriels-800': '#724F3C',
  'industriels-900': '#5F3624',

  // dark-gray
  'dark-gray-100': '#F5F5F5',
  'dark-gray-200': '#EBEBEB',
  'dark-gray-300': '#C3C3C3',
  'dark-gray-400': '#888888',
  'dark-gray-500': '#3A3A3A', // primary
  'dark-gray-600': '#312A2B',
  'dark-gray-700': '#291D1F',
  'dark-gray-800': '#211216',
  'dark-gray-900': '#1B0B10',

  // beige
  'beige-100': '#FEFDFC',
  'beige-200': '#FDFBFA',
  'beige-300': '#F9F7F5',
  'beige-400': '#F4F1EE',
  'beige-500': '#EDE9E6', // primary
  'beige-600': '#CBB5A8',
  'beige-700': '#AA8573',
  'beige-800': '#895A49',
  'beige-900': '#713A2C',
};

export default Colors;
