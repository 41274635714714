import { DocumentsListParams } from 'types/Document';

let prefixer: string | undefined;

const hostname = window.location.hostname;
const port = window.location.port;

switch (hostname) {
  case process.env.REACT_APP_STAGING_HOST:
    prefixer = process.env.REACT_APP_STAGING_API_URL;
    break;
  case process.env.REACT_APP_DEV_HOST:
    prefixer = process.env.REACT_APP_DEV_API_URL;
    break;
  case process.env.REACT_APP_PROD_HOST:
    prefixer = process.env.REACT_APP_PROD_API_URL;
    break;
  default:
    if (port === '3000') prefixer = process.env.REACT_APP_LOCAL_API_URL;
    else prefixer = process.env.REACT_APP_DEV_API_URL;
}

export const AuthUrls = {
  PASSWORD_LOGIN: `${prefixer}/auth/login`,
  RESET_PASSWORD_INIT: `${prefixer}/auth/forgot-password`,
  RESET_PASSWORD: `${prefixer}/auth/reset-password`,
  CREATE_PASSWORD: `${prefixer}/auth/create-password`,
  REFRESH_TOKEN: `${prefixer}/auth/refresh-token`,
  IMPERSONATE: `${prefixer}/auth/impersonate`,
};

export const ExternalUrls = {
  GET_NEWS_RSS: 'https://www.hellio.com/actualites/rss.xml',
};

export const UserUrls = {
  GET_CONNECTED_INFO: `${prefixer}/user/me`,
  GET_ACCOUNT_INFO: `${prefixer}/user/infos`,
  GET_ACCOUNT_OWNER_INFO: `${prefixer}/user/me/owner`,
};

export const NotificationsUrls = {
  GET: `${prefixer}/notifications`,
  MARK_READ: `${prefixer}/notifications/read`,
};

export const MetricsUrls = {
  GET: (path: string = '') => `${prefixer}/metrics/${path}`,
};

export const PickListUrls = {
  GET: (objectType: string, fieldName: string) =>
    `${prefixer}/${objectType}/picklist/${fieldName}`,
};

export const DossiersUrls = {
  ADD: `${prefixer}/dossiers`,
  EXPORT: `${prefixer}/dossiers/export`,
  LIST: `${prefixer}/dossiers`,
  GET_REFERENTS_COMMERCIAUX: `${prefixer}/dossiers/referents-commerciaux`,
  GET: (dossierId: string) => `${prefixer}/dossiers/${dossierId}`,
  OPERATIONS: (dossierId: string, offset: number = 0, limit: number = 10) =>
    `${prefixer}/dossiers/${dossierId}/operations?offset=${offset}&limit=${limit}`,
  OPERATION: (dossierId: string, operationId: string) =>
    `${prefixer}/dossiers/${dossierId}/operations/${operationId}`,
  OPERATION_ATTACHMENTS: (dossierId: string, operationId: string) =>
    `${prefixer}/dossiers/${dossierId}/operations/${operationId}/attachments`,
};

export const AttachmentsUrls = {
  DOWNLOAD_ATTACHMENT: (token: string) =>
    `${prefixer}/attachments/download/${token}`,
  GET_DOWNLOAD: (attachmentId: string) =>
    `${prefixer}/attachments/get-download/${attachmentId}`,
  DELETE: (attachmentId: string) => `${prefixer}/attachments/${attachmentId}`,
};

export const DocumentsUrls = {
  LIST: (params: DocumentsListParams) =>
    `${prefixer}/documents?page=${params.page}&per_page=${params.per_page}`,
  VIEW: (id: string) => `${prefixer}/documents/${id}`,
};

export const SimulateurUrls = {
  FOSTS: (secteur: string) => `${prefixer}/simulateur/fosts/${secteur}`,
  CRITERES_ELIGIBILITE: (fost: string) =>
    `${prefixer}/simulateur/criteres-eligibilite/${fost}`,
  CHAMPS_REQUIS: (fost: string) =>
    `${prefixer}/simulateur/champs-requis/${fost}`,
  RUN: `${prefixer}/simulateur`,
  PRIMES_CONCESSIONNAIRES: `${prefixer}/simulateur/primes-concessionnaire`,
};

export const SimulationsUrls = {
  SAVE: `${prefixer}/simulations`,
  LIST: `${prefixer}/simulations`,
  EXPORT: `${prefixer}/simulations/export`,
  VIEW(id: string) {
    return `${prefixer}/simulations/${id}`;
  },
};

export const DataGouvUrls = {
  ADRESSE_SEARCH: 'https://api-adresse.data.gouv.fr/search/',
  DEPARTEMENTS: 'https://geo.api.gouv.fr/departements',
};
