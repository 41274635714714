import React from 'react';
import clsx from 'clsx';
import { StatusColor } from 'types/Status';

export default function StatusTag({
  status,
  color,
  full,
  className,
}: {
  status?: string;
  color: StatusColor;
  full?: boolean;
  className?: string;
}) {
  return status !== undefined ? (
    <div
      className={clsx(
        'inline-block py-2 px-5 rounded-md -my-2 text-center text-white border border-solid font-medium',
        {
          'w-full': full,
        },
        className,
      )}
      style={{
        backgroundColor: color.bg,
        color: color.text,
        borderColor: color.border,
      }}
    >
      {status}
    </div>
  ) : null;
}
