import { ColorsVariations } from 'const/colors';
import { DossierStatus } from 'types/Dossier';
import { StatusColor } from 'types/Status';

export const getDossierStatusColor = (status: DossierStatus): StatusColor => {
  // Référence couleurs: https://docs.google.com/spreadsheets/d/11_BRm3rdT6hP_KuUXPs7yLJDyG4Xz65vg1oH1sSk6ig/edit?gid=0#gid=0
  switch (status) {
    case DossierStatus.EtudeEnCours:
      return {
        bg: ColorsVariations['bleu-hellio-100'],
        border: ColorsVariations['bleu-hellio-800'],
        text: ColorsVariations['bleu-hellio-800'],
      };

    case DossierStatus.OffreEmise:
    case DossierStatus.ChantierEnCours:
      return {
        bg: ColorsVariations['grand-public-100'],
        border: ColorsVariations['grand-public-600'],
        text: ColorsVariations['grand-public-900'],
      };

    case DossierStatus.DocumentsEnAttente:
      return {
        bg: ColorsVariations['gestionnaire-habitat-100'],
        border: ColorsVariations['gestionnaire-habitat-600'],
        text: ColorsVariations['gestionnaire-habitat-900'],
      };

    case DossierStatus.EnAttenteDeControle:
      return {
        bg: ColorsVariations['pro-renov-100'],
        border: ColorsVariations['pro-renov-600'],
        text: ColorsVariations['pro-renov-900'],
      };

    case DossierStatus.Incomplet:
      return {
        bg: ColorsVariations['gestionnaire-habitat-100'],
        border: ColorsVariations['gestionnaire-habitat-700'],
        text: ColorsVariations['gestionnaire-habitat-900'],
      };

    case DossierStatus.Valide:
      return {
        bg: ColorsVariations['agriculture-100'],
        border: ColorsVariations['agriculture-600'],
        text: ColorsVariations['agriculture-900'],
      };

    case DossierStatus.Termine:
      return {
        bg: ColorsVariations['agriculture-300'],
        border: ColorsVariations['agriculture-600'],
        text: ColorsVariations['dark-gray-900'],
      };

    case DossierStatus.PrimeGlobalePayee:
    case DossierStatus.PrimeBeneficiairePayee:
    case DossierStatus.PrimeProPayee:
      return {
        bg: ColorsVariations['agriculture-200'],
        border: ColorsVariations['agriculture-400'],
        text: ColorsVariations['dark-gray-900'],
      };

    case DossierStatus.Refuse:
    case DossierStatus.Abandon:
      return {
        bg: ColorsVariations['dark-gray-100'],
        border: ColorsVariations['dark-gray-300'],
        text: ColorsVariations['dark-gray-900'],
      };

    case DossierStatus.EnCoursDeValidation:
      return {
        bg: ColorsVariations['entreprise-100'],
        border: ColorsVariations['entreprise-600'],
        text: ColorsVariations['entreprise-900'],
      };

    default:
      return {
        bg: ColorsVariations['dark-gray-100'],
        border: ColorsVariations['dark-gray-300'],
        text: ColorsVariations['dark-gray-900'],
      };
  }
};
