import { MenuProps } from 'antd';
import React from 'react';

import { ReactComponent as PersonIcon } from 'assets/icons/PersonFill.svg';
import { ReactComponent as BookIcon } from 'assets/icons/Book.svg';
import { ContactPopover } from 'layouts/Component/ContactPopover';
import { NotificationPopover } from 'layouts/Component/NotificationPopover';
import { UserRole, UserType } from 'types/User';

export type MenuItem = Required<MenuProps>['items'][number];

export const topNavigationItems = [
  {
    key: '/tableau-de-bord',
    label: 'Tableau de bord',
    hiddenForType: [UserType.PARTNER],
    hiddenForRole: [UserRole.TECHNICIAN],
  },
  {
    key: '/actualites',
    label: 'Actualités',
  },
  {
    key: '/calculer-prime',
    label: 'Calculer une prime',
    hiddenForType: [UserType.PARTNER],
  },
  {
    key: '/tableau-de-bord',
    label: 'Tableau de bord',
    hiddenForType: [UserType.ENTREPRISE_DE_TRAVAUX],
    hiddenForRole: [UserRole.TECHNICIAN],
  },
  {
    key: '/soumettre-dossier',
    label: 'Soumission de dossier',
    hiddenForType: [UserType.ENTREPRISE_DE_TRAVAUX],
  },
  {
    key: '/dossiers',
    label: 'Suivi des dossiers',
  },
  {
    key: '/documents',
    label: 'Documents',
    hiddenForType: [UserType.PARTNER],
  },
  {
    key: '/faq',
    label: 'FAQ',
  },
  {
    key: '/glossaire',
    icon: <BookIcon />,
  },
  {
    key: 'contact',
    icon: <ContactPopover />,
  },
  {
    key: 'userAccount',
    icon: <PersonIcon />,
    children: [
      {
        key: '/mon-compte',
        label: 'Mon compte',
      },
      {
        key: 'logout',
        label: 'Déconnexion',
      },
    ],
  },
  {
    key: 'notifications',
    icon: <NotificationPopover />,
  },
];

export const bottomNavigationItems = [
  {
    key: '/tableau-de-bord',
    label: 'Tableau de bord',
    hiddenForType: [UserType.PARTNER],
    hiddenForRole: [UserRole.TECHNICIAN],
  },
  {
    key: '/actualites',
    label: 'Actualités',
  },
  {
    key: '/tableau-de-bord',
    label: 'Tableau de bord',
    hiddenForType: [UserType.ENTREPRISE_DE_TRAVAUX],
    hiddenForRole: [UserRole.TECHNICIAN],
  },
  {
    key: '/calculer-prime',
    label: 'Calculer une prime',
    hiddenForType: [UserType.PARTNER],
  },
  {
    key: '/soumettre-dossier',
    label: 'Soumission de dossier',
    hiddenForType: [UserType.ENTREPRISE_DE_TRAVAUX],
  },
  {
    key: '/dossiers',
    label: 'Suivi des dossiers',
  },
  {
    key: 'https://www.ecologie.gouv.fr/dispositif-des-certificats-deconomies-denergie',
    label: 'Réglementation',
    target: '_blank',
  },
  {
    key: '/faq',
    label: 'FAQ',
  },
  {
    key: 'https://www.hellio.com/mentions-legales',
    label: 'Mentions légales',
  },
  {
    key: 'https://www.hellio.com/cgu',
    label: "Conditions générales d'utilisation",
  },
  {
    key: 'https://www.hellio.com/politique-confidentialite',
    label: 'Politique de confidentialité',
  },
  {
    key: '/documents',
    label: 'Documents',
    hiddenForType: [UserType.PARTNER],
  },
];

export default topNavigationItems;
