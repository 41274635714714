import { ColorsVariations } from 'const/colors';
import { StatusColor } from 'types/Status';
import { OperationStatus } from 'types/Operation';

export const getDossierOperationStatusColor = (
  status: OperationStatus,
): StatusColor => {
  switch (status) {
    case OperationStatus.Litige:
    case OperationStatus.Incomplet:
      return {
        bg: ColorsVariations['gestionnaire-habitat-100'],
        border: ColorsVariations['gestionnaire-habitat-600'],
        text: ColorsVariations['gestionnaire-habitat-900'],
      };
    case OperationStatus.Refuse:
      return {
        bg: ColorsVariations['dark-gray-100'],
        border: ColorsVariations['dark-gray-300'],
        text: ColorsVariations['dark-gray-900'],
      };

    case OperationStatus.BonPourPrime:
      return {
        bg: ColorsVariations['agriculture-100'],
        border: ColorsVariations['agriculture-600'],
        text: ColorsVariations['agriculture-900'],
      };

    case OperationStatus.RemisAuControle:
    case OperationStatus.DossierEnCoursDeValidation:
    case OperationStatus.PreValide:
    case OperationStatus.ActionEnCours:
    case OperationStatus.NonConforme:
    default:
      return {
        bg: ColorsVariations['grand-public-100'],
        border: ColorsVariations['grand-public-400'],
        text: ColorsVariations['grand-public-900'],
      };
  }
};
